<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/resmum.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <!-- <h1 class="title">Kontak Kami.</h1> -->
          <!-- <div class="text-center">
            <a href="#" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-facebook-square"></i>
            </a>
            <a href="#" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-google-plus"></i>
            </a>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 class="title">Dirkrimum : 089765453564</h4>
            <h4 class="title">Wadirkrimum : 089765453564</h4>
          </div>
        </div>
        <div class="separator separator-primary"></div>
       
      </div>
    </div> -->
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Struktur Organisasi</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/polri vektor.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">AKBP Dian Setyawan, S.H, S.I.K, M.Hum</h4>
                <p class="category text-primary">Wadirkrimum</p>
                <!-- <p class="description">
                  Romina Hadid menjabat Wadirkrimum sejak 2023 sampai sekarang
                </p> -->
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-whatsapp"></i
                ></a>
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/polri vektor.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">KOMBES Yudhis Wibisana, S.I.K., M.H</h4>
                <p class="category text-primary">Dirkrimum</p>
                <!-- <p class="description">
                  Ryan Tompson menjabat Dirkrimum sejak 2023 sampai sekarang
                </p> -->
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-whatsapp"></i
                ></a>
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/polri vektor.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">AKP Ita Rustandi</h4>
                <p class="category text-primary">Kabag Wassidik</p>
                <!-- <p class="description">
                  Eva Jenner menjabat Kabag Wassidik sejak 2023 sampai sekarang
                </p> -->
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-whatsapp"></i
                ></a>
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="img/pakmirodin.jpeg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">AKBP Mi'rodin, S.H.,M.H.</h4>
                <p class="category text-primary">Kasubdit I</p>
                <!-- <p class="description">
                  Romina Hadid menjabat Kasubdit I sejak 2023 sampai sekarang
                </p> -->
                <a target="_blank" href="https://api.whatsapp.com/send?phone=6281380882002&text=Hallo AKBP Mirodin, SH, MH" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-whatsapp"></i
                ></a>
                <a target="_blank" href="https://www.instagram.com/mirodin02/" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a target="_blank" href="https://www.facebook.com/mirodin.arema" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="img/polri vektor.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">AKBP Herfio Zaki,S.I.K.,M.H.</h4>
                <p class="category text-primary">Kasubdit II</p>
                <!-- <p class="description">
                  AKBP Mirodin, SH, MH menjabat Kasubdit II sejak 2023 sampai sekarang
                </p> -->
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-whatsapp"></i
                ></a>
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="img/polri vektor.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Kompol Muhammad Akbar Baskoro Nur Hutomo, S.I.K</h4>
                <p class="category text-primary">Kasubdit III</p>
                <!-- <p class="description">
                  Eva Jenner menjabat Kasubdit III sejak 2023 sampai sekarang
                </p> -->
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-whatsapp"></i
                ></a>
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="img/polwan new.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Kompol Hj. Herlia Hartarani, S.H.,M.H</h4>
                <p class="category text-primary">Kasubdit IV</p>
                <!-- <p class="description">
                  Eva Jenner menjabat Kasubdit III sejak 2023 sampai sekarang
                </p> -->
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-whatsapp"></i
                ></a>
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a target="_blank" href="#" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style></style>
