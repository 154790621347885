<template>

  <div>
    <div class="page-header clear-filter" filter-color="">
      <!-- <parallax
        class="page-header-image"
        style="background-image:url('img/gate_tef.jpeg')"
      >
      </parallax> -->
      <video width="100%" autoplay muted style="margin-top: -50px;" >
        <source :src="someVidio" type="video/mp4">
        <source src="vido_tef.ogg" type="video/ogg">
        Your browser does not support the video tag.
      </video>
    </div>
    
    <!-- <marquee style="font-weight: bold; margin-top: 0px;">
      <hr style="border: 1px solid red;">
      TEF has been focusing fabrication of stainless steel or higher grade materials, such as Ni base alloys materials, Titanium etc., well known as exotic materials.
      <hr style="border: 1px solid red;"> 
    </marquee> -->
    <div class="section">
      <div class="container">
        <div class="text-left" style="margin-top: -500px; text-shadow: 2px 2px black; ">
            <h2 class="title mt-1 text-left text-white animated fadeInDown"><span style="font-size: 50px; color: #7FFF00;">We are</span> pioneers of <br> fabrication with special materials</h2>
        </div>

        <!-- CARD INTRODUCTION -->
        <div class="row" style="margin-top: 170px; margin-bottom: 100px;">
          <div class="col-md-10 col-xl-6 mt-6 text-center" style="margin-right= 800px;">
            <img
              src="img/yunomae.png"
              class="img-fluid rounded animated fadeInDown"
              style="margin-top: 130px;"
            /><br>
            President Director <br>
            Mr. Ryohichi Yunomae <br>
            湯之前 良一 <br>
          </div>
          <div class="col-md-10 ml-auto col-xl-6 mr-auto mt-5">
            <div class="card">
              <div class="container mt-5 mb-5">
                <h3 class="font-weight-bold align-middle">
                  PT TOKKI ENGINEERING <span style="color: green;">AND FABRICATION</span>
                </h3> 
                is providing quality products and good after-sales technical service from the Tropical Island in Indonesia to all over the world.  We are the specialized Company in the welded pressure vessels and sheet metal combining of industrial infrastructure Including Oil & Gas, Petrochemicals, Pharmaceuticals, Fertilizer, Agricultural chemicals, Power Plant and Thermal Processing industries.
                We show the spirit of challenge based on the differential advantage of "Engineering Capability“, “Consulting”, “Workmanship (Proficiency in Welding)”, “Special Materials”, research and develop, manufacture, and sell our products which make our Customers satisfied.
                We believe our Company owes its prosperity to the strong and kind support of our Customers, people working with us, and local citizens. We would like to ask for your continued support and guidance.
              </div>
            </div>
          </div>
        </div>

        <!-- SECOND INTRODUCTION -->
        <div style="color: red:">
          <img
            src="img/navigating-the-future-tradition-meets-innovation3.jpg"
            class=" img-fluid  mt-4"/>
        </div>
      </div>
    </div>

    <!-- OUR KEY TECHNOLOGY -->
    <div class="section section-tabs">
      <div class="container">
        <h3 class="font-weight-bold">Our Key Technologies</h3>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

        <div class="row mt-5">
          <div class="col-md-10 col-xl-6 text-center">
            <img
              src="img/engineering_key.png"
              class="rounded fadeInDown"
            />
          </div>
          <div class="col-md-10 col-xl-6 text-left align-middle">
            <h2 class="font-weight-bold align-middle">
              Engineering <span style="color: green;">Capability</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div class="col-md-10 col-xl-6 text-left align-middle mt-5">
            <h2 class="font-weight-bold align-middle">
              Corrosion <span style="color: green;">Consulting</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div class="col-md-10 col-xl-6 text-center mt-5">
            <img
              src="img/corrosion_consulting.png"
              class=" rounded"
            />
          </div>
          <div class="col-md-10 col-xl-6 text-center mt-5">
            <img
              src="img/welding_key.png"
              class="rounded fadeInDown"
            />
          </div>
          <div class="col-md-10 col-xl-6 text-left align-middle mt-5">
            <h2 class="font-weight-bold align-middle">
              Workmanship <span style="color: green;">( Proficiency in Welding )</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div class="col-md-10 col-xl-6 text-left align-middle mt-5">
            <h2 class="font-weight-bold align-middle">
              Special <span style="color: green;">Materials</span>
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div class="col-md-10 col-xl-6 text-center mt-5">
            <img
              src="img/special_material.png"
              class="rounded fadeInDown"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section mt-4 mb-4">
      <div class="container">
        <!-- SECOND INTRODUCTION -->
        <div style="color: red:">
          <img
            src="img/bussines_mix.jpg"
            class=" img-fluid"/>
        </div>
      </div>
    </div>

    <!-- MOTO TOKKI  -->
    <div class="page-header clear-filter" filter-color="purple">
      <div class="parallax">
        <div class="container" style="">
          <h1 class="text-white text-left pt-5 font-weight-bold text-center">START <span style="font-size: 20px; color: yellow;"> as TOKKI Group watchword </span></h1>
          <h2 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">S</span>kill-up</h2>
          <h6 class="text-white text-left font-weight-bold mt-n4">We will try to improve more the level of individual technology upgrade.</h6>
          <h2 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">T</span>imely</h2>
          <h6 class="text-white text-left font-weight-bold mt-n4">We will strive to supply necessary document, materials and products in a timely manner.</h6>
          <h2 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">A</span>ctive</h2>
          <h6 class="text-white text-left font-weight-bold mt-n4">We will breakway from waiting for instructions and taking proactive stance for work.</h6>
          <h2 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">R</span>esponsibility</h2>
          <h6 class="text-white text-left font-weight-bold mt-n4">We will responsibility for our work.</h6>
          <h2 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">T</span>ry</h2>
          <h6 class="text-white text-left font-weight-bold mt-n4">We are not satisfied with the current situation and will try new things. <br> We will act first and we don't look for excuses as to why we can't do something.</h6>
        </div>
      </div>
    </div>

    <div class="section section-tabs">
      <div class="container">
        <!-- SECOND INTRODUCTION -->
        <div style="color: red:">
          <img
            src="img/exhibition-banner1.jpg"
            class=" img-fluid mt-4"/>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { FormGroupInput, Button} from '@/components';
import config from '@/configs/config';
import Api from '@/helpers/api';
import dataLaporan from '@/services/dataLaporan.service';
import historyPerkara from '@/services/historyPerkara.service';
import visitorCounter from '@/services/visitorCounter.service';
var moment = require('moment');
var someSound = require("@/assets/audio/bs1.mp3");
var someVidio = require("@/assets/vidio/vidio_tef.mp4");

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
    [FormGroupInput.name]: FormGroupInput,
    [Button.name]: Button,
  },
  data () {
      return {
        someSound:someSound,
        someVidio:someVidio+'#t=30',
        moment:moment,
        pagination: {
          page_count: '',
          default: 1,
          page: '',
        },
        onLoading: false,
        table: {
          data: []
        },
        form: {
            add: true,
            title: "Add Data",
            show: false
        },

        laporanData: {}, 
        storageUrl : config.storageUrl,
        loadTimeout: null,
        no_lp: '',
        tokenApi : '',
        newReview: {},

      }
    },
    mounted(){
      this.tokenApi = 'Bearer '+localStorage.getItem('token');
      // var audio = new Audio(this.someSound); // path to file
      // audio.play();
      this.createVisitorCounter()
    },
    methods: {
      createVisitorCounter(param){
        let context = this;               
        Api(context, visitorCounter.createVisitorCounter()).onSuccess(function(response) {    
            context.table.data            = response.data.data.data.data;
            context.pagination.page_count = response.data.data.data.last_page
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.table.data = [];
            }
        }).onFinish(function() {  
            context.form.show  = false;
        })
        .call()
      },
      showLaporan() {
        let context = this; 
        let noLP    = this.no_lp.split('/').join('-') 

        Api(context, dataLaporan.showLaporan(noLP)).onSuccess(function(response) {
            context.laporanData = response.data.data[0]; 
            if (response.data.data.length != 0) {
              context.$notify('Data Berhasil Di Tampilkan', 'success')         
            }else{
              context.$notify('Data Tidak Tersedia', 'error') 
            }
        }).onError(function(error) {                    
            context.$notify('Data Tidak Tersedia', 'error') 
        })
        .call()        
      },
      getHistory(param) {
        let context = this; 
        let noLP    = this.no_lp.split('/').join('-') 
                       
        Api(context, historyPerkara.showHistory(noLP)).onSuccess(function(response) {
            context.table.data = response.data.data;       
        })
        .call()        
      },
      bintang(number){
        this.newReview.bintang = number;
        this.$forceUpdate();
      },
      saveReview(id){
          let api = null;
          let context = this;

          context.newReview.id_history_perkara = id;
          context.newReview.nama               = context.laporanData.nama;

          api = Api(context, historyPerkara.createReview(context.newReview));
          api.onSuccess(function(response) {
              context.getHistory();
              context.newReview = {}
              context.$notify('Penilaian Berhasil Di simpan', 'success')
          }).onError(function(error) {
              context.$notify('Penilaian Gagal Di simpan', 'error')
          }).onFinish(function() {  
          })
          .call();
      },
    }
};
</script>
<style>
  .parallax {
    /* The image used */
    background-image: url("/img/achievement.jpg");

    /* Set a specific height */
    min-height: 500px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
