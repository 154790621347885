<template>
  <div>
    <div class="page-header clear-filter" filter-color="">
      <parallax
        class="page-header-image"
        style="background-image:url('img/gate_tef.jpeg')"
      >
      </parallax>
      <div class="container">
        <h2 class="title mt-5">About Us</h2>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row mt-5">
          <div class="col-md-10 col-xl-6 text-center">
            <img
              src="img/discovery_about.jpg"
              class="rounded animated fadeInDown"
            />
            <h6 class="text-left mt-4">
              Date established  : 16 April 2019  <br>
              Capital  : IDR. 120,000,000,000 <br>
              Shareholder  : TOKKI LTD (99.9%), OKADA SANGYO CO., LTD (0.1%) <br>
              President Director  : Ryohichi Yunomae <br>
              Total employee  : 174 <br>
              Bank  : MUFG Bank, Ltd, Jakarta Branch <br>
              Email address  : sales@tef.co.id <br>
              Website  : www.tef.co.id <br>
            </h6>
          </div>
          <div class="col-md-10 col-xl-6 text-left align-middle">
            <h2 class="font-weight-bold align-middle">
              PT TOKKI ENGINEERING <span style="color: green;">AND FABRICATION</span>
            </h2>
            <h6>
              PT TOKKI ENGINEERING AND FABRICATION (TEF) was founded in 2019 in Indonesia as a Group of TOKKI LTD. to supply fabricated products to Customers all the world with high quality and reasonable price. <br><br>

              TEF has been focusing fabrication of stainless steel or higher grade materials, such as Ni base alloys materials, Titanium etc., well known as exotic materials. <br><br>

              Main products of TEF are pressure vessels such as reactor, vessel, tank, heat exchanger, tower, & prefabricated piping for petrochemical, oil & gas and pharmaceutical industries, and fabricated products for industrial heating such as muffle, radiant tube, rotary kiln, tray, basket, etc., including repairing of fabricated products and repair work at site. <br><br>

              We have been acquiring for the following quality certifications: <br><br>

              1. ISO9001-2015. <br>
              2. ASME U and R stamp. <br>
              3. The National Board Certificate. <br>
              4. MIGAS Certificate. <br>
              5. EBTKE Certificate. <br>
              6. KEMNAKER Certificate. <br><br> 

              And we also can comply other Pressure Vessel Code such as Japanese Code, DOSH (Malaysia) and MOM (Singapore).
            </h6>
          </div>
        </div>
      </div>

        <!-- SECOND INTRODUCTION -->
        <div class="mt-5 container">
          <img
            src="img/new_organization_tef.jpg"
            class=" img-fluid mt-4"/>
        </div>
        <div class="mt-3 container">
          <img
            src="img/business_history.jpg"
            class=" img-fluid mt-4"/>
        </div>
      </div>
    
    <div class="section section-tabs text-center mt-5">
        <h3 class="font-weight-bold">Parent Company</h3>
        <img
          src="img/tokki_ltd.jpg"
          class="rounded"
        />
        <h6 class="text-lowercase mt-3">
          Parent company of PT. Tokki Engineering and Fabrication is Tokki Co,. Ltd <br>
          Detail of the company and business of Tokki Co,. Ltd is as shown in the following website :<br>
          <a href="http://www.tokkiltd.co.jp" target="_BLANK" style="color: green">http://www.tokkiltd.co.jp</a>
        </h6>
      </div>
    <div class="section section-tabs text-center">
      <div class="container">
        <h2 class="title">Our Team</h2>
        <h4>We are a team of creatives people innovation</h4>
        <div class="team">
          <div class="row mt-5">
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="img/mamad.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Mamad Saepulloh</h4>
                <p class="category text-success">Senior Staff</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="img/qodrat.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Qodratulloh</h4>
                <p class="category text-success">Engineering/Design</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="img/hermawan.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Hermawan</h4>
                <p class="category text-success">Cost Control</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="img/subandi.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Subandi</h4>
                <p class="category text-success">Project Control</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- MOTO TOKKI  -->
    <div class="page-header clear-filter" filter-color="purple">
      <div class="parallax">
        <div class="container" style="">
          <h1 class="text-white text-left pt-5 font-weight-bold text-center">START <span style="font-size: 20px; color: yellow;"> with TOKKI </span></h1>
          <div class="row pt-5">
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">Main office & factory  :</span></h6>
              Kawasan Industri Jababeka 1
              Jl.Jababeka XIIB Blok W26
              Cikarang-Bekasi, Jawa Barat Indonesia Kode Pos 17530
              Telp: +62-21 893 6733 (Hunting) <br><br>

              Phone : +62 - 21 8936733 (Hunting)<br>
              Fax   : +62 - 21 8936738<br>
              Email : agus.setiyono@tef.co.id<br>
            </div>
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">2nd office & factory</span></h6>
              Kawasan Industri Krakatau 1,
              Jl. Australia I Kav. C1/2, Kel. Warnasari, Kec. Citangkil
              Kota Cilegon, Banten - Indonesia 42443 <br><br>
              
              Phone : +62 - 254 8317244<br>
              Fax   : +62 - 254 8317244<br>
              Email : sutriono@tef.co.id<br>
            </div>
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">3rd Branch office</span></h6>
              Green Garden, Blok A-4 No. 01 Jl. Dr. Wahidin Sudirohusodo Kel. Dahanrejo, Kec. Kebomas Gresik, Jawa Timur, Indonesia 61124 <br><br>
            
              Phone : +62 - 31 39935914<br>
              Fax   : +62 - 31 39935914<br>
              Email : sales@tef.co.id<br>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
// import { Tabs, TabPane } from '@/components';

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
    // Tabs,
    // TabPane
  }
};
</script>
<style>
   .animated {
     
      padding-top:0px;
      margin-bottom:0px;
      -webkit-animation-duration: 5s;
      animation-duration: 5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
   }
   
   @-webkit-keyframes fadeInDown {
      0% {
         opacity: 0;
         -webkit-transform: translateY(-20px);
      }
      100% {
         opacity: 1;
         -webkit-transform: translateY(0);
      }
   }
   
   @keyframes fadeInDown {
      0% {
         opacity: 0;
         transform: translateY(-50px);
      }
      100% {
         opacity: 1;
         transform: translateY(0);
      }
   }
   
   .fadeInDown {
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
   }
</style>
