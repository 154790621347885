import Service from '@/helpers/service'

export default {

    getVisitorCounter(params) {
        return Service().get('visitor-counter/get-total-visitor');
    },
    getDetailVisitor(params) {
        return Service().get('visitor-counter/get-detail-visitor');
    },
    createVisitorCounter(params) {
        return Service().get('visitor-counter/create');
    },
}