<template>
  <div class="page-header clear-filter" filter-color="">
    <div
      class="page-header-image"
      style="background-image: url('img/gate_tef.jpeg')"
    ></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <card type="login" plain style="margin-top: 50px;">
            <!-- <div slot="header" class="logo-container">
              <img v-lazy="'img/now-logo.png'" alt="" />
            </div> -->

            <fg-input
              class="no-border input-lg mb-1"
              addon-left-icon="now-ui-icons users_circle-08"
              placeholder="Username"
              required
              v-model="username"
              v-on:keyup.enter="login()"
            >
            </fg-input>
            <fg-input
              class="no-border input-lg mb-1"
              addon-left-icon="now-ui-icons objects_key-25"
              placeholder="Password"
              type="password"
              required
              v-model="password"
              v-on:keyup.enter="login()"
            >
            </fg-input>

            <template slot="raw-content">
              <div class="card-footer text-center">
                <n-button type="default" size="sm" class="btn btn-success btn-round btn-lg btn-block" @click.native="login()" style="padding: 10px;">
                  <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
                  <span v-else>
                      <span>Login</span>
                  </span>
                </n-button>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>
<script>
import { Card, Button, FormGroupInput } from '@/components';
import MainFooter from '@/layout/MainFooter';
import config from '@/configs/config';
import axios from 'axios';
import Api from '@/helpers/api';
import akun from '@/services/akun.service';
import bus from '@/eventBus';

export default {
  name: 'login-page',
  bodyClass: 'login-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      username: '',
      password: '',
      onLoading: false,
    };
  },
  methods: {
    login() {           
      if (this.username && this.password) {
        var formData = new FormData()
        formData.append('username',  this.username)
        formData.append('password', this.password)

        axios.post(config.apiUrl +"auth/login", formData)
        .then(response => {
          localStorage.setItem('token', response.data.access_token)                                        
          localStorage.setItem('authenticated', true) 

            // ======== untuk menentukan admin ============
            let context = this;     
            context.onLoading = true;          
            Api(context, akun.indexProfile()).onSuccess(function(response) {
                var dataRole = response.data.data[0];
                context.$notify('Selamat Anda Berhasil Login', 'success')
                localStorage.setItem('role', dataRole.role)
                localStorage.setItem('username', dataRole.full_name)
                // if (dataRole.role == 'Admin') {
                    context.$router.push('/data-laporan')
                // }else{
                //     context.$router.push('/profile')
                // }
            }).onError(function(error) {  
            })
            .call() 
            // ==============================================                                      
            context.onLoading = true;
            this.reloadUser();
        })
        .catch(err => {
         // this.$notify('Username atau Password Salah', 'error')
        })
      } else {
        this.$notify('Error Username Password Required', 'error')
      }
    },
    reloadUser(){
        bus.$emit('RELOAD_USER')
        this.$forceUpdate();
    },
  }
};
</script>
<style></style>
