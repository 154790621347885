<template>
  <div>
    <div class="page-header page-header-xtra-small clear-filter" filter-color="">
      <parallax
        class="page-header-image"
        style="background-image:url('img/resmum.jpg')"
      >
      </parallax>
      <!-- <div class="container">
        <div class="mt-5">
          <img src="img/Logo_Bareskrim.png" width="160" alt="" />
        </div>
        <h3 class="title mt-1">DIREKTORAT RESERSE KRIMINAL UMUM</h3>
        <p class="category">POLDA BANTEN</p>
      </div> -->
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h5 class="card-title font-weight-bold text-center">Form Laporan Masuk</h5><hr>
            <div class="row mt-5">
              <div class="col-lg-6 col-xs-12">
                <p class="font-weight-bold" style="font-size:13px;">NO Laporan &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp; 
                  <input style="border: 1px solid white; background: transparent; font-size: 11px;" size="55" placeholder="NO Laporan" v-model="laporanData.no_lp" :disabled="$route.params.typeForm != 'Add'">
                </p>
                <p class="font-weight-bold" style="font-size:13px;">Nama Pelapor &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp; 
                  <input style="border: 1px solid white; background: transparent;" size="25" placeholder="Nama Pelapor ..." v-model="laporanData.nama">
                </p>
                <p class="font-weight-bold" style="font-size:13px;">Umur &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp; 
                  <input style="border: 1px solid white; background: transparent;" size="5" placeholder="Umur ..." v-model="laporanData.umur"> Tahun
                </p>
                <div class="form-inline">
                  <p class="font-weight-bold" style="font-size:13px;">Jenis Kelamin &nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp; 
                  </p>
                    <select class="form-select form-control mt-n3" aria-label="Default select example" v-model="laporanData.jenis_kelamin" style="width: 150px;" >
                      <option selected>Pilih Jenis Kelamin</option>
                      <option value="Pria">Pria</option>
                      <option value="Wanita">Wanita</option>
                    </select>
                </div>
                <div class="form-inline">
                  <p class="font-weight-bold" style="font-size:13px;">Agama &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp; 
                  </p>
                    <select class="form-select form-control mt-n3" aria-label="Default select example" v-model="laporanData.agama" style="width: 150px;" >
                      <option selected>Pilih Agama</option>
                      <option value="Islam">Islam</option>
                      <option value="Katolik">Katolik</option>
                      <option value="Protestan">Protestan</option>
                      <option value="Hindu">Hindu</option>
                      <option value="Budha">Budha</option>
                      <option value="Kong Hu Chu">Kong Hu Chu</option>
                      <option value="Lain - Lain">Lain - Lain</option>
                    </select>
                </div>
                <p class="font-weight-bold" style="font-size:13px;">No HP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp; 
                  <input style="border: 1px solid white; background: transparent;" size="25" placeholder="No HP ..." v-model="laporanData.no_tlp">
                </p>
                <p class="font-weight-bold" style="font-size:13px;">Alamat &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp; 
                  <textarea style="border-radius: 10px; border: 1px solid #DCDCDC; color: #808080; font-size: 12px; " rows="5" cols="28" v-model="laporanData.alamat"> </textarea>
                </p>
              </div>
              <div class="col-lg-6 col-xs-12">
                <p class="font-weight-bold" style="font-size:13px;">Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp; 
                  <input type="email" style="border: 1px solid white; background: transparent;" size="25" placeholder="Email ..." v-model="laporanData.email">
                </p>
                <div class="form-inline">
                  <p class="font-weight-bold" style="font-size:13px;">Jenis Identitas &nbsp; : &nbsp;&nbsp;&nbsp; 
                  </p>
                    <select class="form-select form-control mt-n3" aria-label="Default select example" v-model="laporanData.jenis_identitas" style="width: 150px;" >
                      <option selected>Pilih Jenis Identitas</option>
                      <option value="KTP">KTP</option>
                      <option value="KTM">KTM</option>
                      <option value="SIM">SIM</option>
                    </select>
                </div>
                <p class="font-weight-bold" style="font-size:13px;">No Identitas &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp; 
                  <input style="border: 1px solid white; background: transparent;" size="24" placeholder="No Identitas ..." v-model="laporanData.no_identitas">
                </p>
                <p class="font-weight-bold" style="font-size:13px;">Perkara &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp; 
                  <input style="border: 1px solid white; background: transparent;" size="24" placeholder="Perkara ..." v-model="laporanData.judul_perkara">
                </p>
                <p class="font-weight-bold" style="font-size:13px;">Tanggal Perkara  : &nbsp;&nbsp;&nbsp; 
                  <input type="date" style="border: 1px solid white; background: transparent;" size="13" placeholder="Tanggal Perkara ..." v-model="laporanData.tanggal_perkara">
                </p>
                <p class="font-weight-bold" style="font-size:13px;">Perkembangan Perkara &nbsp; : &nbsp;&nbsp;&nbsp; 
                  <label class="badge badge-info" v-if="!laporanData.status_perkara">Open</label>
                  <label class="badge badge-info" v-else>{{ laporanData.status_perkara }}</label>
                </p>
                <p class="font-weight-bold" style="font-size:13px;">Deskripsi Perkara &nbsp;: &nbsp;&nbsp;&nbsp; 
                  <textarea style="border-radius: 10px; border: 1px solid #DCDCDC; color: #808080; font-size: 12px; " rows="5" cols="25" v-model="laporanData.deskripsi_perkara"> </textarea>
                </p>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-4">
                <!-- <h5 class="card-title font-weight-bold">Perkembangan Kasus</h5> -->
              </div>
              <div class="col-4">
              </div>
              <div class="col-4">
                <n-button type="success" size="sm" class="float-right mr-3" @click="save()" :disabled="onLoading" v-if="$route.params.typeForm == 'Add' || laporanData.created_by == loginName">
                  <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
                  <span v-else>
                      <span>Save <i class="now-ui-icons ui-1_check" aria-hidden="true"></i></span>
                  </span>
                </n-button>
              </div>
            </div>
            <hr>

            <!-- HISTORY PERKEMBANGAN KASUS -->
            <div v-if="$route.params.typeForm != 'Add'">
              <div class="row mt-5">
                <div class="col-4">
                  <h5 class="card-title font-weight-bold">Perkembangan Kasus</h5>
                </div>
                <div class="col-4">
                </div>
                <div class="col-4">
                  <n-button type="primary" size="sm" class="float-right mr-3" @click="createHistory()" v-if="laporanData.created_by == loginName"><i class="now-ui-icons ui-1_simple-add" aria-hidden="true"></i> Add New</n-button>
                </div>
              </div>
              <div class="scroll">
                <table class="table">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th style="font-size: 12px; text-align: center;">NO</th>
                      <th style="font-size: 12px; text-align: center;">DESKRIPSI PERKEMBANGAN</th>
                      <th style="font-size: 12px; text-align: center;">STATUS</th>
                      <th style="font-size: 12px; text-align: center;">Document</th>
                      <th style="font-size: 12px; text-align: center;">TANGGAL</th>
                      <th style="font-size: 12px; text-align: center;">PENILAIAN PELAPOR KE PENYIDIK</th>
                      <th style="font-size: 12px; text-align: center;" v-if="laporanData.created_by == loginName">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td style="font-size: 13px; text-align: center;" class="align-middle">{{ i + 1 }}</td>
                      <td style="font-size: 13px; text-align: center;" class="align-middle">{{ row.deskripsi_perkembangan }}</td>
                      <td style="font-size: 13px; text-align: center;" class="align-middle">
                        <label class="badge badge-info">{{ row.status }}</label>
                      </td>
                      <td style="font-size: 13px; text-align: center;" v-if="row.document" class="align-middle">
                        <a :href="storageUrl+'/document/'+row.document" target="_blank" >Klik Disini</a>
                      </td>
                      <td style="font-size: 13px; text-align: center;" v-else></td>
                      <td style="font-size: 13px; text-align: center;" class="align-middle"> {{ moment(row.created_at).locale('id').format('L') }} </td>
                      <td style="font-size: 13px; text-align: center;" class="align-middle" v-if="row.bintang">
                        <i class="fa fa-star" aria-hidden="true" style="color: gold" v-for="(star, j) in row.bintang" :key="j"></i>
                        <br>
                        <textarea style="border-radius: 10px; border: 1px solid #DCDCDC; color: #808080; font-size: 12px; " rows="4" cols="40" placeholder="Komentar..." v-model="row.komentar" disabled> </textarea>
                      </td>
                      <td style="font-size: 13px; text-align: center;" v-else></td>
                      <td style="font-size: 13px; text-align: center;" class="align-middle" v-if="row.created_by == loginName">
                        <i class="now-ui-icons design-2_ruler-pencil mr-4" aria-hidden="true" style="cursor: pointer;" @click="editHistory(row.id)" title="Edit"></i>
                        <i class="now-ui-icons ui-1_simple-remove" aria-hidden="true" title="Hapus" style="cursor: pointer;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- MODAL TAMBAH HISTORY KASUS -->
    <modal :show.sync="form.show" headerClasses="justify-content-center">
      <h6 slot="header" class="title title-up" style="margin-top: -15px;">{{ form.title }}</h6><hr style="margin-top: -15px;" class="mb-4">
      <p class="mb-5">
        <label ><small class="font-weight-bold">Deskripsi Perkembangan</small></label><br>
        <textarea style="border-radius: 10px; border: 1px solid #DCDCDC; color: #808080; font-size: 12px; " rows="5" cols="65" v-model="historyPerkara.deskripsi_perkembangan"> </textarea>
        <label class="mt-2"><small class="font-weight-bold">Status Perkembangan</small></label><br>
        <fg-input
          class="input-lg mb-1"
          placeholder="Status Perkembangan"
          required
          v-model="historyPerkara.status">
        </fg-input>
        <label class="mt-2"><small class="font-weight-bold">Document</small></label><br>
        <input type="file" class="input-lg" placeholder="Document" required @change="filesChange">
        <!-- <fg-input
          class="input-lg mb-1"
          placeholder="Document"
          required
          type="file"
          @change="filesChange">
        </fg-input> -->
      </p>
      <template slot="footer">
        <n-button @click.native="form.show = false" size="sm">Close</n-button>
        <n-button type="success" size="sm" class="float-right mr-3" @click="saveHistory()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Save <i class="now-ui-icons ui-1_check" aria-hidden="true"></i></span>
          </span>
        </n-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { FormGroupInput, Button, Modal } from '@/components';
import config from '@/configs/config';
import Api from '@/helpers/api';
var moment = require('moment');
import dataLaporan from '@/services/dataLaporan.service';
import historyPerkara from '@/services/historyPerkara.service';
import akun from '@/services/akun.service';

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
    Modal,
    [FormGroupInput.name]: FormGroupInput,
    [Button.name]: Button,
  },
  data () {
      return {
       moment:moment,
        pagination: {
          page_count: '',
          default: 1,
          page: '',
        },
        onLoading: false,
        table: {
          data: []
        },
        form: {
          add: true,
          title: "Tambah Perkembangan",
          show: false
        },

        laporanData: {}, 
        historyPerkara: {},
        storageUrl : config.storageUrl,
        loadTimeout: null,
        tokenApi : '',
        loginName : '',
      }
    },
    mounted(){
      if (this.$route.params.typeForm == 'Add') {
        this.getNoLpNew();
      }else{
        this.showLaporan(this.$route.params.typeForm);
        this.getHistory(this.$route.params.typeForm);
      }
      this.tokenApi = 'Bearer '+localStorage.getItem('token');
      this.getLoginName();
    },
    methods: {
      getNoLpNew(param) {
        let context = this;               
        Api(context, dataLaporan.getNoLpNew()).onSuccess(function(response) {
            context.laporanData.no_lp = response.data.data; 
            context.$forceUpdate();         
        })
        .call()        
      },
      getLoginName(){
        let context = this;      
        Api(context, akun.indexProfile()).onSuccess(function(response) {
            context.loginName = response.data.data[0].full_name;
        }).onError(function(error) {  
        })
        .call()
      },
      showLaporan(param) {
        let context = this;               
        Api(context, dataLaporan.showLaporan(param)).onSuccess(function(response) {
            context.laporanData = response.data.data[0];          
        })
        .call()        
      },
      save(){
        let api = null;
        let context = this;
        let formData = new FormData();
        this.onLoading = true;

        if (this.laporanData.no_lp != undefined && this.laporanData.nama != undefined && this.laporanData.umur != undefined && this.laporanData.jenis_kelamin != undefined && this.laporanData.agama != undefined && this.laporanData.no_tlp != undefined && this.laporanData.alamat != undefined && this.laporanData.email != undefined && this.laporanData.jenis_identitas != undefined && this.laporanData.no_identitas != undefined && this.laporanData.judul_perkara != undefined && this.laporanData.tanggal_perkara != undefined && this.laporanData.deskripsi_perkara != undefined) {
          formData.append('no_lp', this.laporanData.no_lp);
          formData.append('nama', this.laporanData.nama);
          formData.append('umur', this.laporanData.umur);
          formData.append('jenis_kelamin', this.laporanData.jenis_kelamin);
          formData.append('agama', this.laporanData.agama);
          formData.append('no_tlp', this.laporanData.no_tlp);
          formData.append('alamat', this.laporanData.alamat);
          formData.append('email', this.laporanData.email);
          formData.append('jenis_identitas', this.laporanData.jenis_identitas);
          formData.append('no_identitas', this.laporanData.no_identitas);
          formData.append('judul_perkara', this.laporanData.judul_perkara);
          formData.append('tanggal_perkara', this.laporanData.tanggal_perkara);
          formData.append('deskripsi_perkara', this.laporanData.deskripsi_perkara);
        }else{
          context.onLoading = false
          return alert('Semua Field Wajib Di Isi')
        }

        if (context.$route.params.typeForm == 'Add') {
            api = Api(context, dataLaporan.create(formData));
        } else {
            api = Api(context, dataLaporan.update(this.laporanData.id, formData));
        }
        api.onSuccess(function(response) {
          if (context.$route.params.typeForm == 'Add') {
            context.laporanData = {}
            context.getNoLpNew();
          }
            context.$notify((context.$route.params.typeForm == 'Add') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
        }).onError(function(error) {                    
            context.$notify((context.$route.params.typeForm == 'Add') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'success')
        }).onFinish(function() {  
            context.onLoading = false
        })
        .call();
      },
      // ======== HISTORY KASUS ========  
      getHistory(param) {
        let context = this;               
        Api(context, historyPerkara.showHistory(param)).onSuccess(function(response) {
            context.table.data = response.data.data;       
        })
        .call()  
        context.$forceUpdate();      
      },
      createHistory() {
        this.form.add   = true;
        this.form.show  = true;
        this.form.title = "Tambah Perkembangan";
        this.onLoading  = false
      },
      editHistory(id) {
        let context = this;               
        Api(context, historyPerkara.show(id)).onSuccess(function(response) {
            context.historyPerkara = response.data.data[0];
            context.form.show      = true;
            context.form.title     = 'Edit Perkembangan';                
        })
        .call()        
      },
      filesChange(e) {
          this.historyPerkara.document = e.target.files[0];
      },
      saveHistory(){
        let api = null;
        let context = this;
        let formData = new FormData();
        this.onLoading = true;

        if (this.historyPerkara.deskripsi_perkembangan != undefined && this.historyPerkara.status != undefined) {
          formData.append('no_lp', this.laporanData.no_lp);
          formData.append('deskripsi_perkembangan', this.historyPerkara.deskripsi_perkembangan);
          formData.append('status', this.historyPerkara.status);
          formData.append('document', this.historyPerkara.document);
        }else{
          context.onLoading = false
          return alert('Semua Field Wajib Di Isi')
        }

        if (context.form.title == 'Tambah Perkembangan') {
            api = Api(context, historyPerkara.create(formData));
        } else {
            api = Api(context, historyPerkara.update(this.historyPerkara.id, formData));
        }
        api.onSuccess(function(response) {
          context.$notify((context.form.title == 'Tambah Perkembangan') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
        }).onError(function(error) {                    
            context.$notify((context.form.title == 'Tambah Perkembangan') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'success')
        }).onFinish(function() {  
            context.showLaporan(context.$route.params.typeForm)
            context.getHistory(context.$route.params.typeForm)
            context.form.show      = false;
            context.onLoading      = false;
            context.historyPerkara = {}
        })
        .call();
      },
      remove(id) {
          var r = confirm("Anda yakin ingin menghapus data ini ?");
          if (r == true) {
            let context = this;

            Api(context, historyPerkara.delete(id)).onSuccess(function(response) {
                context.getHistory(context.$route.params.typeForm)
                context.$notify('Data Berhasil di Hapus', 'success')
            }).call();
          }
      },
    }
};
</script>
<style type="text/css">
  .form-inline {
    display: flex;
    align-items: center;
  }
</style>
