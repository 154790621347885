<template>
  <div>
    <div class="page-header page-header-xtra-small clear-filter" filter-color="">
      <parallax
        class="page-header-image"
        style="background-image:url('img/resmum.jpg')"
      >
      </parallax>
      <!-- <div class="container">
        <div class="mt-5">
          <img src="img/Logo_Bareskrim.png" width="160" alt="" />
        </div>
        <h3 class="title mt-1">DIREKTORAT RESERSE KRIMINAL UMUM</h3>
        <p class="category">POLDA BANTEN</p>
      </div> -->
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-4">
                <h5 class="card-title font-weight-bold">Data Laporan Masuk</h5>
              </div>
              <div class="col-3">
              </div>
              <div class="col-5">
                <n-button type="primary" size="sm" class="float-right" @click="detailLaporan('Add')"><i class="now-ui-icons ui-1_simple-add" aria-hidden="true"></i> Add New</n-button>
                <n-button type="default" size="sm" class="float-right mr-2" @click="filter()"><i class="now-ui-icons design_bullet-list-67" aria-hidden="true"></i> Filter</n-button>
                <a :href="apiUrl+'report-pdf/data-laporan?no_lp='+search.no_lp+'&no_identitas='+search.no_identitas+'&nama='+search.nama+'&judul_perkara='+search.judul_perkara+'&status_perkara='+search.status_perkara+'&date='+search.date+'&created_by='+search.created_by+''" target="_BLANK" class="btn btn-sm btn-success mb-4 float-right mr-2" ><i class="now-ui-icons files_paper" aria-hidden="true"></i> Print</a>
              </div>
            </div>
            <div class="scroll">
              <table class="table">
                <thead>
                  <tr style="background-color: #F0F8FF;">
                    <th style="font-size: 12px; text-align: center;">NO</th>
                    <th style="font-size: 12px; text-align: center;">LP NO</th>
                    <th style="font-size: 12px; text-align: center;">NO IDENTITAS</th>
                    <th style="font-size: 12px; text-align: center;">PELAPOR</th>
                    <th style="font-size: 12px; text-align: center;">PERKARA</th>
                    <th style="font-size: 12px; text-align: center;">STATUS</th>
                    <th style="font-size: 12px; text-align: center;">TOTAL PERKEMBANGAN</th>
                    <th style="font-size: 12px; text-align: center;">BULAN KE</th>
                    <th style="font-size: 12px; text-align: center;">TANGGAL PERKARA</th>
                    <th style="font-size: 12px; text-align: center;">NO TLP</th>
                    <th style="font-size: 12px; text-align: center;">EMAIL</th>
                    <th style="font-size: 12px; text-align: center;">CREATED AT</th>
                    <th style="font-size: 12px; text-align: center;">CREATED BY</th>
                    <th style="font-size: 12px; text-align: center;">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, i) in table.data" :key="i">
                    <td style="font-size: 13px; text-align: center;"> {{ i + 1 }} </td>
                    <td style="font-size: 14px; text-align: center;">
                      <label class="badge badge-success" style="cursor: pointer;" @click="detailLaporan(row.no_lp)"> {{row.no_lp}} </label>
                    </td>
                    <td style="font-size: 13px; text-align: center;"> {{row.no_identitas}} </td>
                    <td style="font-size: 13px; text-align: center;"> {{row.nama}} </td>
                    <td style="font-size: 13px; text-align: center;"> {{row.judul_perkara}} </td>
                    <td style="font-size: 13px; text-align: center;">
                      <label class="badge badge-info">{{row.status_perkara}}</label>
                    </td>
                    <td style="font-size: 13px; text-align: center;"> {{ row.total_perkembangan_count }} </td>
                    <td style="font-size: 13px; text-align: center;"> {{ (moment().diff(row.created_at, "month") + 1) }} </td>
                    <td style="font-size: 13px;"> {{ moment(row.tanggal_perkara).locale('id').format('L') }} </td>
                    <td style="font-size: 13px; text-align: center;"> {{row.no_tlp}} </td>
                    <td style="font-size: 13px; text-align: center;"> {{row.email}} </td>
                    <td style="font-size: 13px; text-align: center;"> {{ moment(row.created_at).locale('id').format('L') }} </td>
                    <td style="font-size: 13px; text-align: center;"> {{row.created_by}} </td>
                    <td style="text-align: center;" v-if="row.created_by == loginName">
                      <i class="now-ui-icons ui-1_simple-remove" aria-hidden="true" title="Hapus" style="cursor: pointer;" @click="remove(row.id)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL FILTER -->
    <modal :show.sync="form.show" headerClasses="justify-content-center">
      <h6 slot="header" class="title title-up" style="margin-top: -15px;">{{ form.title }}</h6><hr style="margin-top: -15px;" class="mb-4">
      <p class="mb-5">
        <label class="mt-3">Nomor Laporan</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Nomor Laporan"
          required
          v-model="search.no_lp">
        </fg-input>
        <label class="mt-3">Nomor Identitas</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Nomor Identitas"
          required
          v-model="search.no_identitas">
        </fg-input>
        <label class="mt-3">Nama Pelapor</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Nama Pelapor"
          required
          v-model="search.nama">
        </fg-input>
        <label class="mt-3">Perkara</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Perkara"
          required
          v-model="search.judul_perkara">
        </fg-input>
        <label class="mt-3">Perkembangan Perkara</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Perkembangan Perkara"
          required
          v-model="search.status_perkara">
        </fg-input>
        <label class="mt-3">Created At</label><br>
        <fg-input>
          <el-date-picker
            size="small"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            v-model="search.date">
          </el-date-picker>
        </fg-input>
        <label class="mt-3">Created By</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Created By"
          required
          v-model="search.created_by">
        </fg-input>
      </p>
      <template slot="footer">
        <n-button @click.native="form.show = false" size="sm">Close</n-button>
        <n-button type="success" size="sm" class="float-right mr-3" @click="get()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Filter <i class="now-ui-icons ui-1_check" aria-hidden="true"></i></span>
          </span>
        </n-button>
      </template>
    </modal>

  </div>
</template>
<script>
import { FormGroupInput, Button, Modal } from '@/components';
import { DatePicker } from 'element-ui';

import config from '@/configs/config';
import Api from '@/helpers/api';
var moment = require('moment');
import dataLaporan from '@/services/dataLaporan.service';
import akun from '@/services/akun.service';

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
    Modal,
    [FormGroupInput.name]: FormGroupInput,
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
  },
  data () {
      return {
        moment:moment,
        pagination: {
          page_count: '',
          default: 1,
          page: '',
        },
        onLoading: false,
        table: {
          data: []
        },
        form: {
          add: true,
          title: "Filter",
          show: false
        },

        invoiceData: {}, 
        storageUrl : config.storageUrl,
        apiUrl :config.apiUrl,
        loadTimeout: null,
        search: {
          no_lp: '',
          no_identitas: '',
          nama: '',
          judul_perkara: '',
          status_perkara: '',
          date: '',
          created_by: '',
        },
        tokenApi : '',
        loginName : '',
      }
    },
    mounted(){
      this.get();
      this.getLoginName();
      this.tokenApi = 'Bearer '+localStorage.getItem('token');
    },
    methods: {
      get(param){
        let context = this;               
        Api(context, dataLaporan.index({no_lp: context.search.no_lp, no_identitas: context.search.no_identitas, nama: context.search.nama, judul_perkara: context.search.judul_perkara, status_perkara: context.search.status_perkara, date: context.search.date, created_by: context.search.created_by, page: context.pagination.page})).onSuccess(function(response) {    
            context.table.data            = response.data.data.data.data;
            context.pagination.page_count = response.data.data.data.last_page
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.table.data = [];
            }
        }).onFinish(function() {  
            context.form.show  = false;
        })
        .call()
      },
      getLoginName(){
        let context = this;     
        context.onLoading = true;          
        Api(context, akun.indexProfile()).onSuccess(function(response) {
            context.loginName = response.data.data[0].full_name;
        }).onError(function(error) {  
        })
        .call()
      },
      filter() {
        this.form.add   = true;
        this.form.show  = true;
        this.form.title = "Filter";
        this.onLoading  = false
      },
      detailLaporan(typeForm){
        if (typeForm != 'Add') {
          this.$router.push('/form-laporan/'+typeForm.split('/').join('-'))
        }else{
          this.$router.push('/form-laporan/'+typeForm)
        }
      },
      remove(id) {
          var r = confirm("Anda yakin ingin menghapus data ini ?");
          if (r == true) {
            let context = this;

            Api(context, dataLaporan.delete(id)).onSuccess(function(response) {
                context.get();
                context.$notify('Data Berhasil di Hapus', 'success')
            }).call();
          }
      },

    }
};
</script>
<style type="text/css">
  .scroll { 
    overflow: auto; 
    height: 500px; 
  }
</style>
