<template>
  <div>
    <div class="page-header clear-filter" filter-color="">
      <parallax
        class="page-header-image"
        style="background-image:url('img/image_blog/Welding.jpg')"
      >
      </parallax>
      <div class="container">
        <h2 class="title mt-5">Detail Blog</h2>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <img
              src="img/image_blog/Welding.jpg"
              class="rounded animated fadeInDown"
              width="100%"
              height="30%"
            />
            <h2 class="mt-4 font-weight-bold text-dark">Metode pengelasan dan peralatan las pelat titanium dan batang titanium</h2>
            <h5>KarenaPlat titanium kelas 5, Batang titanium kelas 5, tabung titanium memiliki sifat fisik dan kimia khusus, sehingga proses pengelasannya sangat berbeda dengan logam lainnya. Pengelasan titanium adalah proses pengelasan TiG yang menggunakan argon inert untuk melindungi area pengelasan secara efektif. Sebelum menggunakan argon, periksa sertifikat pabrik pada botol untuk memverifikasi kemurnian argon, lalu periksa apakah katup botol bocor. Atau tanda kegagalan.

Saat mengelas pelat titanium dan batang titanium Grade 5, harus dipastikan bahwa:

(1) Logam di zona pengelasan tidak akan tercemar oleh gas aktif N, O, H dan elemen pengotor berbahaya C, Fe, Mn, dll. Di atas 250 derajat.

(2) Argon: argon murni kelas industri, dengan kemurnian tidak kurang dari 99,98 persen dan kadar air kurang dari 50Mg/m3.

(3) Struktur berbutir kasar tidak dapat terbentuk.

(4) Tegangan sisa pengelasan yang besar dan deformasi sisa tidak dapat dihasilkan. Oleh karena itu, proses pengelasan harus mengikuti urutan konstruksi yang telah ditentukan, mengikuti standar manajemen kualitas proses secara ketat, dan menerapkan kontrol kualitas dari seluruh proses. Jadikan faktor manusia, mesin, material, dan metode dalam keadaan terkontrol dengan baik, untuk memastikan kualitas pengelasan tabung titanium dalam periode konstruksi yang wajar.

Peralatan dan bahan las titanium:

Mesin las: Mesin las DC TiG diadopsi. Mesin las harus memastikan karakteristik kerja dan karakteristik penyesuaian yang sangat baik, dan dilengkapi dengan ammeter dan voltmeter yang baik.

Obor las: digunakan obor las TiG berpendingin air tipe QS-75 derajat /500. Obor las harus memiliki karakteristik struktur sederhana, ringan dan tahan lama, badan obor rapat, insulasi yang baik, aliran udara yang stabil, penjepit tungsten yang kokoh, dan cocok untuk pengelasan di berbagai posisi.

Tabung pengiriman argon adalah tabung plastik semi-kaku, selang karet dan bahan higroskopis lainnya tidak cocok. Ini harus digunakan secara eksklusif dan tidak digunakan secara seri dengan pipa yang mengalirkan gas lain. Pipa gas argon tidak boleh terlalu panjang, agar tidak menyebabkan ketidakstabilan aliran udara akibat penurunan tekanan yang berlebihan, umumnya tidak melebihi 30m.

Perlengkapan las: gunakan kerah pipa baja tahan karat atau tembaga austenitik, baut pengunci, dll. untuk merakit pelat dan aksesori titanium. Harus dipastikan bahwa ada gaya penjepitan tertentu pada pelat dan aksesori titanium www.lh-ti.com untuk memastikan bahwa sumbu konsisten dan celahnya seragam dan sesuai.

Peralatan dan alat bantu: penutup pelindung argon, penggiling, kikir khusus, sikat kawat baja tahan karat, dll.

Kawat las titanium: Kelas kawat las adalah ERTi-2, dan pemilihan kawat las harus memenuhi persyaratan berikut:

(1) Komposisi kimia dan sifat mekanik dari kawat las harus setara dengan logam tidak mulia;

(2) Jika lasan membutuhkan plastisitas tinggi, kawat las dengan kemurnian lebih tinggi dari logam dasar harus digunakan. Kawat las harus diperiksa ulang sebelum digunakan, dan periksa sertifikat pabrik dan sertifikat kualitas; permukaan kawat las harus bersih, bebas dari oksidasi, retakan, pengelupasan, bekas luka dan inklusi terak.



Kawat las dengan kemurnian lebih tinggi dari logam dasar harus digunakan. Bahan kawat las harus diperiksa ulang sebelum digunakan, 2 jika pengelasan membutuhkan plastisitas tinggi. Periksa sertifikat pabrik dan sertifikat kualitas; permukaan kawat las harus bersih, bebas dari oksidasi, retakan, pengelupasan, bekas luka dan inklusi terak.

Karena sifat fisik dan kimia khusus pelat dan batang titanium, proses pengelasannya sangat berbeda dari logam lain. Pengelasan titanium adalah proses pengelasan TiG yang menggunakan argon inert untuk melindungi area pengelasan secara efektif. Sebelum menggunakan argon, periksa sertifikat pabrik pada botol untuk memverifikasi kemurnian argon, lalu periksa apakah katup botol bocor. Atau tanda kegagalan.

Saat mengelas pelat dan batang titanium, harus dipastikan bahwa:

Logam di zona pengelasan tidak akan tercemar oleh gas aktif N0H dan elemen pengotor berbahaya seperti CFeMn di atas 250 derajat . Kemurnian tidak boleh lebih rendah dari 99,98 persen, dan kadar air harus kurang dari 50Mg/m32. Argon: argon murni tingkat industri.

Struktur berbutir kasar tidak dapat dibentuk. Proses pengelasan harus mengikuti urutan konstruksi yang telah ditentukan, dan 4 tidak dapat menghasilkan tegangan sisa las yang besar dan sisa deformasi. jadi. Sesuai ketat dengan standar manajemen kualitas proses, pelaksanaan seluruh proses kontrol kualitas. Jadikan faktor manusia, mesin, material, dan metode dalam keadaan terkontrol dengan baik, untuk memastikan kualitas pengelasan tabung titanium dalam periode konstruksi yang wajar.</h5>

          </div>
          <div class="col-lg-3 section section-tabs">
            <div class="container">
              <h5 class="text-dark font-weight-bold">Most Popular Blog</h5>
              <div class="font-weight-bold p-3 mt-3 cards">
                <span class="text-secondary">#1</span> Apa Itu Pipa Seamless?
              </div>
              <div class="font-weight-bold p-3 cards">
                <span class="text-secondary">#2</span> Metode pengelasan dan peralatan las pelat titanium dan batang titanium
              </div>
              <div class="font-weight-bold p-3 cards">
                <span class="text-secondary">#3</span> Titanium dengan Kekuatan dan Kelebihannya, Dipakai di Mana Saja?
              </div>
              <div class="font-weight-bold p-3 cards">
               <span class="text-secondary">#4</span>  Fabrication Piping
              </div>
              <div class="font-weight-bold p-3 cards">
                <span class="text-secondary">#5</span> Fabrication Mufle
              </div>
              <div class="font-weight-bold p-3 cards">
                <span class="text-secondary">#6</span> Fabrication Radiant Tube
              </div>
              <div class="font-weight-bold p-3 cards">
                <span class="text-secondary">#7</span> Fabrication Other Equipment
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- MOTO TOKKI  -->
    <div class="page-header clear-filter mt-5" filter-color="purple">
      <div class="parallax">
        <div class="container" style="">
          <h1 class="text-white text-left pt-5 font-weight-bold text-center">START <span style="font-size: 20px; color: yellow;"> with TOKKI </span></h1>
          <div class="row pt-5">
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">Main office & factory  :</span></h6>
              Kawasan Industri Jababeka 1
              Jl.Jababeka XIIB Blok W26
              Cikarang-Bekasi, Jawa Barat Indonesia Kode Pos 17530
              Telp: +62-21 893 6733 (Hunting) <br><br>

              Phone : +62 - 21 8936733 (Hunting)<br>
              Fax   : +62 - 21 8936738<br>
              Email : agus.setiyono@tef.co.id<br>
            </div>
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">2nd office & factory</span></h6>
              Kawasan Industri Krakatau 1,
              Jl. Australia I Kav. C1/2, Kel. Warnasari, Kec. Citangkil
              Kota Cilegon, Banten - Indonesia 42443 <br><br>
              
              Phone : +62 - 254 8317244<br>
              Fax   : +62 - 254 8317244<br>
              Email : sutriono@tef.co.id<br>
            </div>
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">3rd Branch office</span></h6>
              Green Garden, Blok A-4 No. 01 Jl. Dr. Wahidin Sudirohusodo Kel. Dahanrejo, Kec. Kebomas Gresik, Jawa Timur, Indonesia 61124 <br><br>
            
              Phone : +62 - 31 39935914<br>
              Fax   : +62 - 31 39935914<br>
              Email : sales@tef.co.id<br>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { Carousel, CarouselItem } from 'element-ui';

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  },
  methods: {
    toDetailProduct(param){
      this.$router.push('/detail-product/'+param)
    }
  }
};
</script>
<style>
   .animated {
     
      padding-top:0px;
      margin-bottom:0px;
      -webkit-animation-duration: 5s;
      animation-duration: 5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
   }
   
   @-webkit-keyframes fadeInDown {
      0% {
         opacity: 0;
         -webkit-transform: translateY(-20px);
      }
      100% {
         opacity: 1;
         -webkit-transform: translateY(0);
      }
   }
   
   @keyframes fadeInDown {
      0% {
         opacity: 0;
         transform: translateY(-50px);
      }
      100% {
         opacity: 1;
         transform: translateY(0);
      }
   }
   
   .fadeInDown {
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
   }


  .cards:hover{
    box-shadow: 5px 6px 6px 2px #e9ecef;
    transform: scale(1.1);
    cursor: pointer;;
  }
</style>
