<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/resmum.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <!-- <h1 class="title">Kontak Kami.</h1> -->
          <!-- <div class="text-center">
            <a href="#" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-facebook-square"></i>
            </a>
            <a href="#" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-google-plus"></i>
            </a>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h4 class="title">Dirkrimum : 089765453564</h4>
            <h4 class="title">Wadirkrimum : 089765453564</h4>
          </div>
        </div>
        <div class="separator separator-primary"></div>
       
      </div>
    </div> -->
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Visitor Counter</h2><hr>
        <div class="team">
          <table class="table table-striped">
            <thead>
              <tr style="background-color: #F0F8FF;">
                <th colspan="2" style="font-size: 12px; text-align: center;">Visitor Counter</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="font-size: 13px; text-align: center;"> Today </td>
                <td style="font-size: 13px; text-align: left;"> {{ totalToday }} </td>
              </tr>
              <tr>
                <td style="font-size: 13px; text-align: center;"> Yesterday </td>
                <td style="font-size: 13px; text-align: left;"> {{ totalYesterday }} </td>
              </tr>
              <tr>
                <td style="font-size: 13px; text-align: center;"> This Month </td>
                <td style="font-size: 13px; text-align: left;"> {{ totalThisMonth }} </td>
              </tr>
              <tr>
                <td style="font-size: 13px; text-align: center;"> Last Month </td>
                <td style="font-size: 13px; text-align: left;"> {{ totalLastMonth }} </td>
              </tr>
              <tr>
                <td style="font-size: 13px; text-align: center;"> This Year </td>
                <td style="font-size: 13px; text-align: left;"> {{ totalThisYear }} </td>
              </tr>
              <tr>
                <td style="font-size: 13px; text-align: center;"> Last Year </td>
                <td style="font-size: 13px; text-align: left;"> {{ totalLastYear }} </td>
              </tr>
              <tr>
                <td style="font-size: 13px; text-align: center; font-weight: bold;"> All </td>
                <td style="font-size: 13px; text-align: left; font-weight: bold;"> {{ totalVisitor }} </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
import Api from '@/helpers/api';
import visitorCounter from '@/services/visitorCounter.service';

export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      totalToday: '',
      totalYesterday: '',
      totalThisMonth: '',
      totalLastMonth: '',
      totalThisYear: '',
      totalLastYear: '',
      totalVisitor: '',
    };
  },
  mounted(){
    this.getDetailVisitor()
  },
  methods: {
    getDetailVisitor(param){
      let context = this;               
      Api(context, visitorCounter.getDetailVisitor()).onSuccess(function(response) {    
          context.totalToday     = response.data.data.totalToday;
          context.totalYesterday = response.data.data.totalYesterday;
          context.totalThisMonth = response.data.data.totalThisMonth;
          context.totalLastMonth = response.data.data.totalLastMonth;
          context.totalThisYear  = response.data.data.totalThisYear;
          context.totalLastYear  = response.data.data.totalLastYear;
          context.totalVisitor   = response.data.data.totalVisitor;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.totalVisitor = '';
          }
      }).onFinish(function() {  
      })
      .call()
    },
  }
};
</script>
<style></style>
