<template>
  <div>
    <div class="page-header page-header-xtra-small clear-filter" filter-color="">
      <parallax
        class="page-header-image"
        style="background-image:url('img/resmum.jpg')"
      >
      </parallax>
      <!-- <div class="container">
        <div class="mt-5">
          <img src="img/Logo_Bareskrim.png" width="160" alt="" />
        </div>
        <h3 class="title mt-1">DIREKTORAT RESERSE KRIMINAL UMUM</h3>
        <p class="category">POLDA BANTEN</p>
      </div> -->
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-4">
                <h5 class="card-title font-weight-bold">Data Account</h5>
              </div>
              <div class="col-3">
              </div>
              <div class="col-5">
                <n-button type="primary" size="sm" class="float-right" @click="create()"><i class="now-ui-icons ui-1_simple-add" aria-hidden="true"></i> Add New</n-button>
                <n-button type="default" size="sm" class="float-right mr-2" @click="filter()"><i class="now-ui-icons design_bullet-list-67" aria-hidden="true"></i> Filter</n-button>
                <!-- <a :href="apiUrl+'report-pdf/data-laporan?no_lp='+search.no_lp+'&no_identitas='+search.no_identitas+'&nama='+search.nama+'&judul_perkara='+search.judul_perkara+'&status_perkara='+search.status_perkara+'&date='+search.date+'&created_by='+search.created_by+''" target="_BLANK" class="btn btn-sm btn-success mb-4 float-right mr-2" ><i class="now-ui-icons files_paper" aria-hidden="true"></i> Print</a> -->
              </div>
            </div>
            <div class="scroll">
              <table class="table">
                <thead>
                  <tr style="background-color: #F0F8FF;">
                    <th style="font-size: 12px; text-align: center;">NO</th>
                    <th style="font-size: 12px; text-align: center;">Nama Lengkap</th>
                    <th style="font-size: 12px; text-align: center;">Pangkat</th>
                    <th style="font-size: 12px; text-align: center;">Jabatan</th>
                    <th style="font-size: 12px; text-align: center;">Unit</th>
                    <th style="font-size: 12px; text-align: center;">NRP</th>
                    <th style="font-size: 12px; text-align: center;">NO HP</th>
                    <th style="font-size: 12px; text-align: center;">Username</th>
                    <th style="font-size: 12px; text-align: center;">Role</th>
                    <th style="font-size: 12px; text-align: center;">Created At</th>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, i) in table.data" :key="i">
                    <td style="font-size: 13px; text-align: center;"> {{ i + 1 }} </td>
                    <td style="font-size: 13px; text-align: center;"> {{ row.full_name }} </td>
                    <td style="font-size: 13px; text-align: center;"> {{ row.pangkat }} </td>
                    <td style="font-size: 13px; text-align: center;"> {{ row.jabatan }} </td>
                    <td style="font-size: 13px; text-align: center;"> {{ row.unit }} </td>
                    <td style="font-size: 13px; text-align: center;"> {{ row.nik }} </td>
                    <td style="font-size: 13px; text-align: center;"> {{ row.no_hp }} </td>
                    <td style="font-size: 13px; text-align: center;"> {{ row.username }} </td>
                    <td style="font-size: 13px; text-align: center;"> {{ row.role }} </td>
                    <td style="font-size: 13px; text-align: center;"> {{ moment(row.created_at).locale('id').format('L') }} </td>
                    <td>
                      <i class="now-ui-icons design-2_ruler-pencil mr-4" aria-hidden="true" style="cursor: pointer;" @click="edit(row.id)" title="Edit"></i>
                      <i class="now-ui-icons ui-1_simple-remove" aria-hidden="true" title="Hapus" style="cursor: pointer;" @click="remove(row.id)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL ADD AKUN -->
    <modal :show.sync="form.show" headerClasses="justify-content-center">
      <h6 slot="header" class="title title-up" style="margin-top: -15px;">{{ form.title }}</h6><hr style="margin-top: -15px;" class="mb-4">
      <p class="mb-5">
        <label class="mt-3">Nama Lengkap</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Nama Lengkap"
          required
          v-model="akunData.full_name">
        </fg-input>
        <label class="mt-3">Pangkat</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Pangkat"
          required
          v-model="akunData.pangkat">
        </fg-input>
        <label class="mt-3">Jabatan</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Jabatan"
          required
          v-model="akunData.jabatan">
        </fg-input>
        <label class="mt-3">Unit</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Unit"
          required
          v-model="akunData.unit">
        </fg-input>
        <label class="mt-3">NRP</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="NRP"
          required
          v-model="akunData.nik">
        </fg-input>
        <label class="mt-3">NO HP</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="NO HP"
          required
          v-model="akunData.no_hp">
        </fg-input>
        <label class="mt-3">Username</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Username"
          required
          v-model="akunData.username">
        </fg-input>
        <label class="mt-3">Password</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Password"
          required
          v-model="akunData.password">
        </fg-input>
        <label class="mt-3">Role</label><br>
        <select class="form-select form-control" aria-label="Default select example" v-model="akunData.role" >
          <option selected>Pilih Role</option>
          <option value="Admin">Admin</option>
          <option value="User">User</option>
        </select>
      </p>
      <template slot="footer">
        <n-button @click.native="form.show = false" size="sm">Close</n-button>
        <n-button type="success" size="sm" class="float-right mr-3" @click="save()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Save <i class="now-ui-icons ui-1_check" aria-hidden="true"></i></span>
          </span>
        </n-button>
      </template>
    </modal>

    <!-- MODAL FILTER -->
    <modal :show.sync="formFilter.show" headerClasses="justify-content-center">
      <h6 slot="header" class="title title-up" style="margin-top: -15px;">{{ formFilter.title }}</h6><hr style="margin-top: -15px;" class="mb-4">
      <p class="mb-5">
        <label class="mt-3">Nama Lengkap</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Nama Lengkap"
          required
          v-model="search.full_name">
        </fg-input>
        <label class="mt-3">Pangkat</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Pangkat"
          required
          v-model="search.pangkat">
        </fg-input>
        <label class="mt-3">Jabatan</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Jabatan"
          required
          v-model="search.jabatan">
        </fg-input>
        <label class="mt-3">Unit</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Unit"
          required
          v-model="search.unit">
        </fg-input>
        <label class="mt-3">NRP</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="NRP"
          required
          v-model="search.nik">
        </fg-input>
        <label class="mt-3">Username</label><br>
        <fg-input
          class="no-border input-lg mb-1"
          placeholder="Username"
          required
          v-model="search.username">
        </fg-input>
        <label class="mt-3">Role</label><br>
        <select class="form-select form-control" aria-label="Default select example" v-model="search.role" >
          <option selected>Pilih Role</option>
          <option value="Admin">Admin</option>
          <option value="User">User</option>
        </select>
      </p>
      <template slot="footer">
        <n-button @click.native="form.show = false" size="sm">Close</n-button>
        <n-button type="success" size="sm" class="float-right mr-3" @click="get()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Filter <i class="now-ui-icons ui-1_check" aria-hidden="true"></i></span>
          </span>
        </n-button>
      </template>
    </modal>

  </div>
</template>
<script>
import { FormGroupInput, Button, Modal } from '@/components';
import { DatePicker } from 'element-ui';

import config from '@/configs/config';
import Api from '@/helpers/api';
import akun from '@/services/akun.service';
var moment = require('moment');

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
    Modal,
    [FormGroupInput.name]: FormGroupInput,
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
  },
  data () {
      return {
        moment:moment,
        pagination: {
          page_count: '',
          default: 1,
          page: '',
        },
        onLoading: false,
        table: {
          data: []
        },
        form: {
          add: true,
          title: "Add New",
          show: false
        },
        formFilter: {
          add: true,
          title: "Filter",
          show: false
        },

        akunData: {}, 
        storageUrl : config.storageUrl,
        apiUrl :config.apiUrl,
        loadTimeout: null,
        search: {
          full_name: '',
          pangkat: '',
          jabatan: '',
          unit: '',
          nik: '',
          username: '',
          role: '',
        },
        tokenApi : '',
      }
    },
    mounted(){
      this.get();
      this.tokenApi = 'Bearer '+localStorage.getItem('token');
    },
    methods: {
      get(param){
        let context = this;               
        Api(context, akun.index({full_name: context.search.full_name, pangkat: context.search.pangkat, jabatan: context.search.jabatan, unit: context.search.unit, nik: context.search.nik, username: context.search.username, role: context.search.role, page: context.pagination.page})).onSuccess(function(response) {    
            context.table.data            = response.data.data.data.data;
            context.pagination.page_count = response.data.data.data.last_page
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.table.data = [];
            }
        }).onFinish(function() {  
            context.form.show  = false;
        })
        .call()
      },
      filter() {
        this.formFilter.add   = true;
        this.formFilter.show  = true;
        this.formFilter.title = "Filter";
        this.onLoading  = false
      },
      detailLaporan(typeForm){
        this.$router.push('/form-laporan/'+typeForm)
      },
      create() {
        this.form.add   = true;
        this.form.show  = true;
        this.form.title = "Add New";
        this.onLoading  = false
      },
      edit(id) {
        let context = this;               
        Api(context, akun.show(id)).onSuccess(function(response) {
            context.akunData   = response.data.data;
            context.form.show  = true;
            context.form.title = 'Edit Data';                
        })
        .call()        
      },
      save(){
        let api = null;
        let context = this;
        let formData = new FormData();
        this.onLoading = true;

        if (context.akunData.full_name != undefined && context.akunData.username != undefined && context.akunData.role != undefined) {
          formData.append('full_name', context.akunData.full_name);
          formData.append('pangkat', (context.akunData.pangkat == undefined) ? '' : context.akunData.pangkat);
          formData.append('jabatan', (context.akunData.jabatan == undefined) ? '' : context.akunData.jabatan);
          formData.append('unit', (context.akunData.unit == undefined) ? '' : context.akunData.unit);
          formData.append('nik', (context.akunData.nik == undefined) ? '' : context.akunData.nik);
          formData.append('no_hp', (context.akunData.no_hp == undefined) ? '' : context.akunData.no_hp);
          formData.append('username', context.akunData.username);
          formData.append('password', context.akunData.password);
          formData.append('role', context.akunData.role);
        }else{
          context.onLoading = false
          return alert('Semua Field Wajib Di Isi')
        }

        if (context.form.title == 'Add New') {
            api = Api(context, akun.create(formData));
        } else {
            api = Api(context, akun.update(context.akunData.id, formData));
        }
        api.onSuccess(function(response) {
          context.get()
          context.$notify((context.form.title == 'Tambah Perkembangan') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
        }).onError(function(error) {                    
            context.$notify((context.form.title == 'Tambah Perkembangan') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'success')
        }).onFinish(function() {  
            context.form.show = false;
            context.onLoading = false;
            context.akunData  = {}
        })
        .call();
      },
      remove(id) {
          var r = confirm("Anda yakin ingin menghapus data ini ?");
          if (r == true) {
            let context = this;

            Api(context, akun.delete(id)).onSuccess(function(response) {
                context.get();
                context.$notify('Data Berhasil di Hapus', 'success')
            }).call();
          }
      },

    }
};
</script>
<style type="text/css">
  .scroll { 
    overflow: auto; 
    height: 500px; 
  }
</style>
